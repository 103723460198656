import { AsyncLocalStorage } from "async_hooks";
import type pino from "pino";
import { getLogger } from "@pam/common/logger";
import { log } from "@pam/common/base-logger";

export interface AsyncStorage {
  logger?: pino.Logger;
}

// conditionally return an instance of AsyncLocalStorage or a mock object for browser use of getLogger() (pino supports browser use)
/* istanbul ignore next */
export const asyncLocalStorage = AsyncLocalStorage
  ? new AsyncLocalStorage<AsyncStorage>()
  : ({
      getStore(): AsyncStorage {
        return { logger: log };
      },
      run() {},
      disable() {},
      enterWith() {},
      exit() {},
    } as AsyncLocalStorage<AsyncStorage>);

export const instrumentAsync = async <T>(functionToTrace: () => Promise<T>) => {
  const storage: AsyncStorage = {};
  return asyncLocalStorage.run(storage, async () => {
    const logger = getLogger();
    storage.logger = logger;
    return functionToTrace();
  });
};

export const instrument = <T>(functionToTrace: () => T) => {
  const storage: AsyncStorage = {};
  return asyncLocalStorage.run(storage, () => {
    const logger = getLogger();
    storage.logger = logger;
    return functionToTrace();
  });
};
